import React from 'react'
import { Container, Flex, Box } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import Icon from '@solid-ui-components/ContentIcon'

const RealReviews = ({
  content: {collection},
}) => (
  <Container
    sx={{
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'left',
    }}
  >  
    <Box
      sx={{
        backgroundColor: '#7abb69',
        display: 'flex',
        p: 2,
        borderRadius: '15px',
        width: '100%',
        maxWidth: '700px',
      }}
    > 
      <Box
        sx={{
          flexShrink: 0,
          mr: 3,
        }}
      >
        <Icon
          sx={{
            p: [2],
            backgroundColor: '#308f16',
            borderRadius: '10px',
          }}
          content={collection[0].icon}
        />
      </Box>
      <Box
        sx={{
          
        }}
      >
        <ContentText
          sx={{
            m: [0],
            lineHeight: 1.3
          }}
          content={[{
            text: "Compliant with Googles Terms of Service",
            color: "white",
            variant: "h5"
          }]} />
        <ContentText
          sx={{
            lineHeight: 1.1
          }}
          content={[{
            text: "We only help collect authentic reviews from actual customers.",
            color: "omegaLight",
          }]} />
      </Box>
    </Box>
  </Container>
)

export default WithDefaultContent(RealReviews)
