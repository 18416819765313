import React from 'react'
import { Container, Flex, Box, css } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import Divider from '@solid-ui-components/Divider'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import ContentButtons from '@solid-ui-components/ContentButtons'
import Icon from '@solid-ui-components/ContentIcon'

const styles = {
  demoVideo: {
    width: '800px',
    height: '448px',
    '@media screen and (max-width: 820px)': {
      width: '700px',
      height: '392px',
    },
    '@media screen and (max-width: 720px)': {
      width: '600px',
      height: '336px',
    },
    '@media screen and (max-width: 620px)': {
      width: '500px',
      height: '280px'
    },
    '@media screen and (max-width: 520px)': {
      width: '400px',
      height: '224px'
    },
    '@media screen and (max-width: 420px)': {
      width: '100%',
      height: '175px'
    },
  }
};

const WhyUsSection = ({
  content: {collection},
}) => (
  <Container
    sx={{
      width: ['95%', '100%'],
    }}
  >
    <Box
      sx={{
        mt: 5,
        mb: 6,
        textAlign: [`center`]
      }}
    >
      <ContentText content={{
          color: "omegaDark",
          text: "Why SRM",
          variant: "h2",
        }}
      />
    </Box>
    <Flex
      sx={{
        flexDirection: ['column', 'column', 'row'],
      }}
    >
      <Box
        sx={{
          mr: [null, null, 4],
          flexShrink: 0,
          mb: [3]
        }}
      >
        <Icon
          content={collection[1].icon}
          size='sm'
        />
      </Box>
      <Box>
        <ContentText content={[{
          text: "Optimized Review Requests",
          color: "omegaDarker",
          variant: "h3"
        },
        {
          text: "Not all review request are equal. See how SRM gets every review possible.",
          color: "omegaDarker",
          variant: "medium"
        }]} />
        <Box
          sx={{
            display: 'flex',
            borderRadius: '20px',
            overflow: 'hidden',
            border: 'solid black 1px',
          }}
        >
          <iframe css={css(styles.demoVideo)} src="https://www.youtube.com/embed/iQbp7e-5Rbk?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </Box>
        {collection[1].buttons && <Flex
          sx={{
            mt: [2],
          }}
        >
          <ContentButtons content={collection[1].buttons} />
        </Flex>}
      </Box>
    </Flex>
    <Divider space='6' />
    <Flex
      sx={{
        flexDirection: ['column', 'column', 'row'],
      }}
    >
      <Box
        sx={{
          mr: [null, null, 4],
          flexShrink: 0,
          mb: [3]
        }}
      >
        <Icon
          content={collection[0].icon}
          size='sm'
        />
      </Box>
      <Box
        sx={{
          
        }}
      >
        <ContentText content={[{
          text: "Get Review or It’s Free",
          color: "omegaDarker",
          variant: "h3"
        },
        {
          text: "Our unique results-based billing means you are only charged if you get additional reviews.",
          color: "omegaDarker",
          variant: "medium"
        },
        {
          text: "How is results-based billing better?",
          color: "omegaDarker",
          variant: "h5"
        }]}/>

        <ul
          style={{color:'black'}}
        >
          <li>
            <ContentText content={{
              text: "It's risk free; No additional reviews means no bill",
              color: "omegaDarker",
              variant: "medium"
            }} />
          </li>
          <li>
            <ContentText content={{
              text: "You know we are focused on getting you every review possible",
              color: "omegaDarker",
              variant: "medium"
            }} />
          </li>
          <li>
            <ContentText content={{
              text: "Seasonal businesses pay less when they are doing less business",
              color: "omegaDarker",
              variant: "medium"
            }} />
          </li>
        </ul>
        {collection[0].buttons && <Flex
          sx={{
            mt: [4],
          }}
        >
          <ContentButtons content={collection[0].buttons} />
        </Flex>}
      </Box>
    </Flex>
  </Container>
)

export default WithDefaultContent(WhyUsSection)
