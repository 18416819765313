import React from 'react'
import { Container, Flex, Box } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const ResultsSection = ({
  content: { images }
}) => (
  <Container variant='wide'>
    <Box
      sx={{
        mb: [5],
        textAlign: [`center`]
      }}
    >
      <ContentText content={[{
        color: "omegaDarker",
        space: null,
        text: "Grow Reviews",
        textGroup: null,
        variant: "h3",
      },{
        text: "Quickly boost your online reputation and attract new customers",
        color: "omegaDark",
        variant: "medium",
      }]} />
    </Box>
    <Flex
      sx={{
        alignItems: [null, null, null, `center`],
        flexDirection: [
          `column`,
          null,
          null,
          `row`
        ]
      }}
    >
      <Box
        sx={{
          flexBasis: [null, null, null, `1/3`],
          position: `relative`,
          textAlign: `center`
        }}
      >
        <ContentImages
          sx={{
            maxWidth: ['90%', '600px', null, '90%']
          }}
          content={{ images: [images[0]] }}
          imagePosition='center'
        />
      </Box>
      <Box
        sx={{
          flexBasis: [null, null, null, `1/3`],
          textAlign: `center`,
          alignItems: 'center',
          justifyContent: 'center',
          pt: [6, null, null, 4],
          pb: [6, null, null, 4],
          pl: [2, 4, null, 4],
          pr: [2, 4, null, 4],
        }}
      >
        <ContentText
          sx={{
            m: 0,
            fontSize: ['1.6rem', null, null, '1.3rem']
          }}
          content={{
            text: "\"The results are impressive. 8 Reviews in less than 2 weeks. I highly recommend.\"",
            color: "omegaDark"
          }}
        />
        <ContentText
          sx={{
            fontSize: ['1.6rem', null, null, '1.3rem'],
            fontWeight: '600',
            pt: 1
          }}
          content={{
            text: "Nick",
            color: "omegaDark"
          }}
        />
      </Box>
      <Box
        sx={{
          flexBasis: [null, null, null, `1/3`],
          position: `relative`,
          textAlign: `center`,
        }}
      >
        <ContentImages
          sx={{
            maxWidth: ['90%', '600px', null, '90%']
          }}
          content={{ images: [images[1]] }}
          imagePosition='center'
        />
      </Box>
    </Flex>
    <Flex
      sx={{
        alignItems: [null, null, null, `center`],
        flexDirection: [
          `column`,
          null,
          null,
          `row`
        ]
      }}
    >
      <Box
        sx={{
          flexBasis: [null, null, null, `1/3`],
          textAlign: `center`,
          alignItems: 'center',
          justifyContent: 'center',
          pt: [6, null, null, 4],
          pb: [6, null, null, 4],
          pl: [2, 4, null, 4],
          pr: [2, 4, null, 4],
        }}
      >
        <ContentText
          sx={{
            m: 0,
            fontSize: ['1.6rem', null, null, '1.3rem']
          }}
          content={{
            text: "\"Truly a simple and effective way to collect reviews - worked for us on day 1.\"",
            color: "omegaDark"
          }}
        />
        <ContentText
          sx={{
            fontSize: ['1.6rem', null, null, '1.3rem'],
            fontWeight: '600',
            pt: 1
          }}
          content={{
            text: "David",
            color: "omegaDark"
          }}
        />
      </Box>
      <Box
        sx={{
          flexBasis: [null, null, null, `1/3`],
          position: `relative`,
          textAlign: `center`,
          display: ['none', null, null, 'block']
        }}
      >
        <ContentImages
          sx={{
            maxWidth: ['90%', '600px', null, '90%']
          }}
          content={{ images: [images[2]] }}
          imagePosition='center'
        />
      </Box>
      <Box
        sx={{
          flexBasis: [null, null, null, `1/3`],
          textAlign: `center`,
          display: ['none', null, null, 'block'],
          alignItems: 'center',
          justifyContent: 'center',
          pt: [6, null, null, 4],
          pb: [6, null, null, 4],
          pl: [2, 4, null, 4],
          pr: [2, 4, null, 4],
        }}
      >
        <ContentText
          sx={{
            m: 0,
            fontSize: ['1.6rem', null, null, '1.3rem']
          }}
          content={{
            text: "\"This app has already generated me at least 3 positive Google reviews! Once you set it, you can forget it\"",
            color: "omegaDark"
          }}
        />
        <ContentText
          sx={{
            fontSize: ['1.6rem', null, null, '1.3rem'],
            fontWeight: '600',
            pt: 1
          }}
          content={{
            text: "Kris",
            color: "omegaDark"
          }}
        />
      </Box>
    </Flex>
  </Container>
)

export default WithDefaultContent(ResultsSection)
