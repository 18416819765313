import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Contact from '@solid-ui-blocks/CallToAction/Block02'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'
import GatsbyConfig from '../../../gatsby-config';
import Hero from '../../components/review-requests/Hero';
import ResultsSection from '../../components/review-generation/ResultsSection';
import HowItWorksSection from '../../components/review-generation/HowItWorksSection';
import WhyUsSection from '../../components/review-generation/WhyUsSection';
import CustomFeaturesWithPhoto from '../../components/CustomFeaturesWithPhoto'

const ReviewGenerationPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo
        title='Get More Business Reviews | Must-see Pricing | Perfect for Small Businesses'
        description={`Get reviews or it's free. Collect more authentic Google reviews from your customers. Perfect for Small Businesses. Quick setup, zero maintenance and must-see pricing.`}
        keywords={['get more reviews', 'collect reviews', 'review requests', 'marketing automation', 'google my business', 'google reviews', 'quickbooks online', 'small business']}
        siteUrl={GatsbyConfig.siteMetadata.siteUrl}
        thumbnail={{
          hero: {
            src: '/srm_acronym_logo.png'
          }
        }}
      />
      {/* Blocks */}
      <Header />
      <Divider space='6' />
      <Hero content={content['hero']} />
      <Divider space='6' />
      <ResultsSection content={content['results-section']} />
      <Divider space='6' />
      <Container variant='wide' sx={styles.howItWorksContainer}>
        <HowItWorksSection content={content['how-it-works-section']} realReviewscontent={content['real-reviews']} />
      </Container>
      <Divider space='6' />
      <WhyUsSection content={content['why-us-section']} />
      <Divider space='6' />
      <CustomFeaturesWithPhoto content={content['qb-integration']} />
      <Divider space='6' />
      <Contact content={content['lower-cta']} />
      <Divider space='6' />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query reviewRequestBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/review-generation"] } }
    ) {
      nodes {
        ...NewCoolBlockContent
      }
    }
  }
`

export default ReviewGenerationPage
