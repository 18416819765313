import React from 'react'
import { Flex, Box } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import Divider from '@solid-ui-components/Divider'
import ContentImages from '@solid-ui-components/ContentImages'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import ContentButtons from '@solid-ui-components/ContentButtons'
import RealReviews from '../../components/review-generation/RealReviews';


const HowItWorksSection = ({
  content: {images, buttons},
  realReviewscontent
}) => (
  <>
    <div id="how-it-works"></div>
    <Box
      sx={{
        mt: 5,
        mb: 5,
        textAlign: [`center`]
      }}
    >
      <ContentText content={{
          color: "omegaDark",
          text: "How it Works",
          variant: "h2",
        }}
      />
    </Box>
    <Divider space='4' />
    <Box
      sx={{
        textAlign: [`center`]
      }}
    >
      <ContentText
        content={[{
          color: "omegaDarker",
          text: "Add Customers",
        }]}
        sx={{
          fontSize: ['30px', '30px', '40px'],
        }}
      />
      <Divider space='3' />
      <Flex sx={{ justifyContent: `center`, flexWrap: `wrap` }}>
        <Box
          sx={{ flexBasis: [`1`, `1`, `1/2`, `1/3`], p: 2 }}
        >
          <ContentText content={{
              text: "Automatic",
              color: "omegaDark",
              variant: "medium"
            }} />
          <ContentImages
            content={{images: [images[0]]}}
            imagePosition='center'
          />
        </Box>
        <Box
          sx={{ flexBasis: [`1`, `1`, `1/2`, `1/3`], p: 2 }}
        >
          <ContentText content={{
              text: "Bulk",
              color: "omegaDark",
              variant: "medium"
            }} />
          <ContentImages
            content={{images: [images[1]]}}
            imagePosition='center'
          />
        </Box>
        <Box
          sx={{ flexBasis: [`1`, `1`, `1/2`, `1/3`], p: 2 }}
        >
          <ContentText content={{
              text: "Individually",
              color: "omegaDark",
              variant: "medium"
            }} />
          <ContentImages
            content={{images: [images[2]]}}
            imagePosition='center'
          />
        </Box>
      </Flex>
      <Divider space='6' />
      <ContentText
        content={[{
          color: "omegaDarker",
          text: "Send Review Requests",
        }]}
        sx={{
          fontSize: ['30px', '30px', '40px'],
        }}
      />
      <Divider space='3' />
      <Flex sx={{ justifyContent: `center`, flexWrap: `wrap` }}>
        <Box
          sx={{ flexBasis: [`1`, `1`, `60%`, `45%`], p: 2 }}
        >
          <ContentText content={{
              text: "Email",
              color: "omegaDark",
              variant: "medium"
            }} />
          <ContentImages
            content={{images: [images[3]]}}
            imagePosition='center'
            />
        </Box>
        <Box
          sx={{ flexBasis: [`80%`, `80%`, `40%`, `28%`], p: 2 }}
        >
          <ContentText content={{
              text: "SMS Text",
              color: "omegaDark",
              variant: "medium"
            }} />
          <ContentImages
            content={{images: [images[4]]}}
            imagePosition='center'
          />
        </Box>
      </Flex>
      <Divider space='6' />
      <ContentText
        content={[{
          color: "omegaDarker",
          text: "Customers Post Reviews",
        }]}
        sx={{
          fontSize: ['30px', '30px', '40px'],
        }}
      />
      <Divider space='3' />
      <Flex sx={{ justifyContent: `center`, flexWrap: `wrap` }}>
        <Box
          sx={{ p: 2 }}
        >
          <ContentImages
            sx={{
              width: [`100%`, `80%`],
              maxWidth: '600px'
            }}
            content={{images: [images[5]]}}
            imagePosition='center'
          />
        </Box>
      </Flex>
      <Divider space='3' />
      <RealReviews content={realReviewscontent} />
      <Divider space='6' />
      <ContentText
        content={[{
          color: "omegaDarker",
          text: "You Get More Calls & Clicks",
        }]}
        sx={{
          fontSize: ['30px', '30px', '40px'],
        }}
      />
      <Divider space='3' />
      <Flex sx={{ justifyContent: `center`, flexWrap: `wrap` }}>
        <Box
          sx={{ p: 2 }}
        >
          <ContentImages
            sx={{
              width: [`100%`, `80%`],
              maxWidth: '600px'
            }}
            content={{images: [images[6]]}}
            imagePosition='center'
          />
        </Box>
      </Flex>
      {buttons && <Flex
        sx={{
          mt: [6],
          mb: [5],
          justifyContent: 'center'
        }}
      >
        <ContentButtons content={buttons} />
      </Flex>}
    </Box>
  </>
)

export default WithDefaultContent(HowItWorksSection)
