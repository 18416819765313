import React from 'react'
import { Container, Flex, Box } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import Divider from '@solid-ui-components/Divider'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const Hero = ({
  content: { text = [], images, buttons },
  reverse
}) => (
  <Container>   
    <Flex
      sx={{
        alignItems: [null, null, null, `center`],
        flexDirection: [
          `column`,
          null,
          null,
          `row`
        ]
      }}
    >
      <Box
        sx={{
          flexBasis: `50%`,
          mb: [4, null, null, null],
          textAlign: [`center`, null, null, `left`]
        }}
      >
        <ContentText content={[
          {
            text: "Review Requests",
            color: "beta",
            variant: "h4"
          },
          {
            text: "Attract More Reviews",
            color: "omegaDarker",
            variant: "h1"
          },
          {
            text: "Invite customers to review your business on Google with email and SMS text messages",
            color: "omegaDark",
            variant: "medium"
          }
        ]} />
        {buttons && (
          <>
            <Divider space={3} />
            <ContentButtons content={buttons} />
            <Divider space={3} />
          </>
        )}
      </Box>
      <Box
        sx={{
          flexBasis: [null, null, null, `50%`],
          ml: [null, null, null, 5],
          position: `relative`,
          textAlign: `center`
        }}
      >
        <ContentImages
          content={{ images }}
          loading='eager'
          imagePosition='center'
        />
      </Box>
    </Flex>
  </Container>
)

export default WithDefaultContent(Hero)
